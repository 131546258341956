// page init
jQuery(function(){
  initFixedScrollBlock();
  initMobileNav();
  initSameHeight();
  initTinyWinHeight();
  if (!Drupal.settings.isRGAA) {
    initBannerPro();
  }
});

function initTinyWinHeight() {
  TinyWinHeight.init();
}

// mobile menu init
function initMobileNav() {
	jQuery('body').mobileNav({
		hideOnClickOutside: true,
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener',
		menuDrop: '.nav-drop'
	});
}

function initBannerPro() {
  var bannerPro = jQuery('.banner--blue');
  var win = jQuery(window);
  var lastScrollTop = 0;
  var className = 'is-show';
  var isShow = false;
  var footer = jQuery('#footer');

  function scrollHandler(event) {
    var st = jQuery(this).scrollTop();
    if (st > 100 && ((st + footer.outerHeight() + win.height()) < footer.offset().top)) {
      if (!isShow) {
        isShow = true;
        bannerPro.addClass(className);
      }
    } else {
      bannerPro.removeClass(className);
      isShow = false;
    }
  }

  win.on('load resize orientationchange scroll', scrollHandler);
}


// initialize fixed blocks on scroll
function initFixedScrollBlock() {
	var win = jQuery(window);
	var scrolledClass1 = 'fixed-position';
	var scrolledClass2 = 'position';

	var header = jQuery('#header');
	// var search = jQuery('.page-questions-reponses .view-header').first();
	var filter;
	var filterHeight = 0;
	var isScrolled1 = false;
	var isScrolled2 = false;

	var extraNavigationOffset = 250;
	var lastScrollTop = 0;

	win.on('load resize orientationchange scroll', scrollHandler);

	function scrollHandler(event) {
		var st = jQuery(this).scrollTop();
		var shouldUpdate = document.getElementsByClassName('grayscale').length == 0 && window.matchMedia("(min-width: 1200px)").matches;
		var isScrollToTop = st < lastScrollTop;
		if (isScrollToTop || window.matchMedia("(max-width: 767px)").matches || (!Drupal.settings.isFront && window.matchMedia("(max-width: 1200px)").matches) ) {
			if (shouldUpdate) {
				if (st > extraNavigationOffset) {
					if (!isScrolled1) {
						header.addClass(scrolledClass1);
						isScrolled1 = true;
					}
				} else if (isScrolled1) {
					header.removeClass(scrolledClass1);
					isScrolled1 = false;
				}

				if (st > 50) {
					if (!isScrolled2) {
						// Le header ne doit pas disparaitre
						header.addClass(scrolledClass2);
						isScrolled2 = true;
					}
				} else if (isScrolled2) {
					header.removeClass(scrolledClass2);
					isScrolled2 = false;
				}
			} else if (window.matchMedia("(max-width: 1200px)").matches) {
				header.addClass(scrolledClass1);
			}
		}
		else {
			header.removeClass(scrolledClass1);
			header.removeClass(scrolledClass2);
			isScrolled1 = false;
			isScrolled2 = false;
		}
		lastScrollTop = st;
	};
}

// align blocks height
function initSameHeight() {
	jQuery('.same-height-holder').sameHeight({
		elements: '.same-height',
		flexible: true,
		multiLine: true,
		biggestHeight: true
	});
}


// detect scroll position
DetectScrollPosition = (function($) {
	'use strict';

	var win = $(window),
		header = $('body'),
		EXTRA_SPACE = 15;

	var activeClassTop = 'scroll-top',
		activeClassBottom = 'scroll-bottom';

	var	scrollPosition = 0,
		scrollPositionPrevius = 0;

	var toggleClasses = function (dir) {
		if (dir) {
			header.addClass(activeClassTop);
			header.removeClass(activeClassBottom);
		} else {
			header.removeClass(activeClassTop);
			header.addClass(activeClassBottom);
		}
	};

	var loadHandler = function() {
		header.removeClass(activeClassTop);
		header.addClass(activeClassBottom);
	};

	var scrollHandler = function() {
		var winScrollTop = win.scrollTop();

		scrollPosition = winScrollTop;

		if (scrollPosition <= scrollPositionPrevius) {
			toggleClasses(true);
		} else {
			if (winScrollTop > EXTRA_SPACE) {
				toggleClasses(false);
			}
		}

		scrollPositionPrevius = scrollPosition;
	};

	win.on({
		load  : loadHandler,
		scroll: scrollHandler
	});

}(jQuery));

/*
 * Tiny Win Height
 */
 TinyWinHeight = (function($) {
 	'use strict';

 	var win = $(window);
 	var container = $('.tiny-win-heigth');
 	var containerItems = container.find('.tiny-win-item');
 	var skipHeader = $('#header');
 	var skipFooter = $('#footer');

 	function initialize() {

 		function resizeWindow() {
 			var height = win.height() - (getSkipSizes().header + getSkipSizes().footer);
 			containerItems.css({ height: height });
 		}

 		function getSkipSizes() {
 			return {
 				header: skipHeader.outerHeight(),
 				footer: skipFooter.outerHeight()
 			};
 		}

 		resizeWindow();
 		win.on('resize orientationchange', resizeWindow);
 	}

 	return {
 		init: initialize
 	};

 }(jQuery));

/*
 * Simple Mobile Navigation
 */
 ;(function($) {
 	function MobileNav(options) {
 		this.options = $.extend({
 			container: null,
 			hideOnClickOutside: false,
 			menuActiveClass: 'nav-active',
 			menuOpener: '.nav-opener',
 			menuDrop: '.nav-drop',
 			toggleEvent: 'click',
 			outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
 		}, options);
 		this.initStructure();
 		this.attachEvents();
 	}
 	MobileNav.prototype = {
 		initStructure: function() {
 			this.page = $('html');
 			this.container = $(this.options.container);
 			this.opener = this.container.find(this.options.menuOpener);
 			this.drop = this.container.find(this.options.menuDrop);
 		},
 		attachEvents: function() {
 			var self = this;

 			if(activateResizeHandler) {
 				activateResizeHandler();
 				activateResizeHandler = null;
 			}

 			this.outsideClickHandler = function(e) {
 				if(self.isOpened()) {
 					var target = $(e.target);
 					if(!target.closest(self.opener).length && !target.closest(self.drop).length) {
 						self.hide();
 					}
 				}
 			};

 			this.openerClickHandler = function(e) {
 				e.preventDefault();
 				self.toggle();
 			};

 			this.opener.on(this.options.toggleEvent, this.openerClickHandler);
 		},
 		isOpened: function() {
 			return this.container.hasClass(this.options.menuActiveClass);
 		},
 		show: function() {
 			this.container.addClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		hide: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		toggle: function() {
 			if(this.isOpened()) {
 				this.hide();
 			} else {
 				this.show();
 			}
 		},
 		destroy: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			this.opener.off(this.options.toggleEvent, this.clickHandler);
 			this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 		}
 	};

 	var activateResizeHandler = function() {
 		var win = $(window),
 		doc = $('html'),
 		resizeClass = 'resize-active',
 		flag, timer;
 		var removeClassHandler = function() {
 			flag = false;
 			doc.removeClass(resizeClass);
 		};
 		var resizeHandler = function() {
 			if(!flag) {
 				flag = true;
 				doc.addClass(resizeClass);
 			}
 			clearTimeout(timer);
 			timer = setTimeout(removeClassHandler, 500);
 		};
 		win.on('resize orientationchange', resizeHandler);
 	};

 	$.fn.mobileNav = function(options) {
 		return this.each(function() {
 			var params = $.extend({}, options, {container: this}),
 			instance = new MobileNav(params);
 			$.data(this, 'MobileNav', instance);
 		});
 	};
 }(jQuery));

/*
 * jQuery SameHeight plugin
 */
 ;(function($){
 	$.fn.sameHeight = function(opt) {
 		var options = $.extend({
 			skipClass: 'same-height-ignore',
 			leftEdgeClass: 'same-height-left',
 			rightEdgeClass: 'same-height-right',
 			elements: '>*',
 			flexible: false,
 			multiLine: false,
 			useMinHeight: false,
 			biggestHeight: false
 		},opt);
 		return this.each(function(){
 			var holder = $(this), postResizeTimer, ignoreResize;
 			var elements = holder.find(options.elements).not('.' + options.skipClass);
 			if(!elements.length) return;

			// resize handler
			function doResize() {
				elements.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', '');
				if(options.multiLine) {
					// resize elements row by row
					resizeElementsByRows(elements, options);
				} else {
					// resize elements by holder
					resizeElements(elements, holder, options);
				}
			}
			doResize();

			// handle flexible layout / font resize
			var delayedResizeHandler = function() {
				if(!ignoreResize) {
					ignoreResize = true;
					doResize();
					clearTimeout(postResizeTimer);
					postResizeTimer = setTimeout(function() {
						doResize();
						setTimeout(function(){
							ignoreResize = false;
						}, 10);
					}, 100);
				}
			};

			// handle flexible/responsive layout
			if(options.flexible) {
				$(window).bind('resize orientationchange fontresize', delayedResizeHandler);
			}

			// handle complete page load including images and fonts
			$(window).bind('load', delayedResizeHandler);
		});
 	};

	// detect css min-height support
	var supportMinHeight = typeof document.documentElement.style.maxHeight !== 'undefined';

	// get elements by rows
	function resizeElementsByRows(boxes, options) {
		var currentRow = $(), maxHeight, maxCalcHeight = 0, firstOffset = boxes.eq(0).offset().top;
		boxes.each(function(ind){
			var curItem = $(this);
			if(curItem.offset().top === firstOffset) {
				currentRow = currentRow.add(this);
			} else {
				maxHeight = getMaxHeight(currentRow);
				maxCalcHeight = Math.max(maxCalcHeight, resizeElements(currentRow, maxHeight, options));
				currentRow = curItem;
				firstOffset = curItem.offset().top;
			}
		});
		if(currentRow.length) {
			maxHeight = getMaxHeight(currentRow);
			maxCalcHeight = Math.max(maxCalcHeight, resizeElements(currentRow, maxHeight, options));
		}
		if(options.biggestHeight) {
			boxes.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', maxCalcHeight);
		}
	}

	// calculate max element height
	function getMaxHeight(boxes) {
		var maxHeight = 0;
		boxes.each(function(){
			maxHeight = Math.max(maxHeight, $(this).outerHeight());
		});
		return maxHeight;
	}

	// resize helper function
	function resizeElements(boxes, parent, options) {
		var calcHeight;
		var parentHeight = typeof parent === 'number' ? parent : parent.height();
		boxes.removeClass(options.leftEdgeClass).removeClass(options.rightEdgeClass).each(function(i){
			var element = $(this);
			var depthDiffHeight = 0;
			var isBorderBox = element.css('boxSizing') === 'border-box' || element.css('-moz-box-sizing') === 'border-box' || element.css('-webkit-box-sizing') === 'border-box';

			if(typeof parent !== 'number') {
				element.parents().each(function(){
					var tmpParent = $(this);
					if(parent.is(this)) {
						return false;
					} else {
						depthDiffHeight += tmpParent.outerHeight() - tmpParent.height();
					}
				});
			}
			calcHeight = parentHeight - depthDiffHeight;
			calcHeight -= isBorderBox ? 0 : element.outerHeight() - element.height();

			if(calcHeight > 0) {
				element.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', calcHeight);
			}
		});
		boxes.filter(':first').addClass(options.leftEdgeClass);
		boxes.filter(':last').addClass(options.rightEdgeClass);
		return calcHeight;
	}
}(jQuery));

/*
 * Utility module
 */
 lib = {
 	hasClass: function(el,cls) {
 		return el && el.className ? el.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)')) : false;
 	},
 	addClass: function(el,cls) {
 		if (el && !this.hasClass(el,cls)) el.className += " "+cls;
 	},
 	removeClass: function(el,cls) {
 		if (el && this.hasClass(el,cls)) {el.className=el.className.replace(new RegExp('(\\s|^)'+cls+'(\\s|$)'),' ');}
 	},
 	extend: function(obj) {
 		for(var i = 1; i < arguments.length; i++) {
 			for(var p in arguments[i]) {
 				if(arguments[i].hasOwnProperty(p)) {
 					obj[p] = arguments[i][p];
 				}
 			}
 		}
 		return obj;
 	},
 	each: function(obj, callback) {
 		var property, len;
 		if(typeof obj.length === 'number') {
 			for(property = 0, len = obj.length; property < len; property++) {
 				if(callback.call(obj[property], property, obj[property]) === false) {
 					break;
 				}
 			}
 		} else {
 			for(property in obj) {
 				if(obj.hasOwnProperty(property)) {
 					if(callback.call(obj[property], property, obj[property]) === false) {
 						break;
 					}
 				}
 			}
 		}
 	},
 	event: (function() {
 		var fixEvent = function(e) {
 			e = e || window.event;
 			if(e.isFixed) return e; else e.isFixed = true;
 			if(!e.target) e.target = e.srcElement;
 			e.preventDefault = e.preventDefault || function() {this.returnValue = false;};
 			e.stopPropagation = e.stopPropagation || function() {this.cancelBubble = true;};
 			return e;
 		};
 		return {
 			add: function(elem, event, handler) {
 				if(!elem.events) {
 					elem.events = {};
 					elem.handle = function(e) {
 						var ret, handlers = elem.events[e.type];
 						e = fixEvent(e);
 						for(var i = 0, len = handlers.length; i < len; i++) {
 							if(handlers[i]) {
 								ret = handlers[i].call(elem, e);
 								if(ret === false) {
 									e.preventDefault();
 									e.stopPropagation();
 								}
 							}
 						}
 					};
 				}
 				if(!elem.events[event]) {
 					elem.events[event] = [];
 					if(elem.addEventListener) elem.addEventListener(event, elem.handle, false);
 					else if(elem.attachEvent) elem.attachEvent('on'+event, elem.handle);
 				}
 				elem.events[event].push(handler);
 			},
 			remove: function(elem, event, handler) {
 				var handlers = elem.events[event];
 				for(var i = handlers.length - 1; i >= 0; i--) {
 					if(handlers[i] === handler) {
 						handlers.splice(i,1);
 					}
 				}
 				if(!handlers.length) {
 					delete elem.events[event];
 					if(elem.removeEventListener) elem.removeEventListener(event, elem.handle, false);
 					else if(elem.detachEvent) elem.detachEvent('on'+event, elem.handle);
 				}
 			}
 		};
 	}()),
 	queryElementsBySelector: function(selector, scope) {
 		scope = scope || document;
 		if(!selector) return [];
 		if(selector === '>*') return scope.children;
 		if(typeof document.querySelectorAll === 'function') {
 			return scope.querySelectorAll(selector);
 		}
 		var selectors = selector.split(',');
 		var resultList = [];
 		for(var s = 0; s < selectors.length; s++) {
 			var currentContext = [scope || document];
 			var tokens = selectors[s].replace(/^\s+/,'').replace(/\s+$/,'').split(' ');
 			for (var i = 0; i < tokens.length; i++) {
 				token = tokens[i].replace(/^\s+/,'').replace(/\s+$/,'');
 				if (token.indexOf('#') > -1) {
 					var bits = token.split('#'), tagName = bits[0], id = bits[1];
 					var element = document.getElementById(id);
 					if (element && tagName && element.nodeName.toLowerCase() != tagName) {
 						return [];
 					}
 					currentContext = element ? [element] : [];
 					continue;
 				}
 				if (token.indexOf('.') > -1) {
 					var bits = token.split('.'), tagName = bits[0] || '*', className = bits[1], found = [], foundCount = 0;
 					for (var h = 0; h < currentContext.length; h++) {
 						var elements;
 						if (tagName == '*') {
 							elements = currentContext[h].getElementsByTagName('*');
 						} else {
 							elements = currentContext[h].getElementsByTagName(tagName);
 						}
 						for (var j = 0; j < elements.length; j++) {
 							found[foundCount++] = elements[j];
 						}
 					}
 					currentContext = [];
 					var currentContextIndex = 0;
 					for (var k = 0; k < found.length; k++) {
 						if (found[k].className && found[k].className.match(new RegExp('(\\s|^)'+className+'(\\s|$)'))) {
 							currentContext[currentContextIndex++] = found[k];
 						}
 					}
 					continue;
 				}
 				if (token.match(/^(\w*)\[(\w+)([=~\|\^\$\*]?)=?"?([^\]"]*)"?\]$/)) {
 					var tagName = RegExp.$1 || '*', attrName = RegExp.$2, attrOperator = RegExp.$3, attrValue = RegExp.$4;
 					if(attrName.toLowerCase() == 'for' && this.browser.msie && this.browser.version < 8) {
 						attrName = 'htmlFor';
 					}
 					var found = [], foundCount = 0;
 					for (var h = 0; h < currentContext.length; h++) {
 						var elements;
 						if (tagName == '*') {
 							elements = currentContext[h].getElementsByTagName('*');
 						} else {
 							elements = currentContext[h].getElementsByTagName(tagName);
 						}
 						for (var j = 0; elements[j]; j++) {
 							found[foundCount++] = elements[j];
 						}
 					}
 					currentContext = [];
 					var currentContextIndex = 0, checkFunction;
 					switch (attrOperator) {
 						case '=': checkFunction = function(e) { return (e.getAttribute(attrName) == attrValue) }; break;
 						case '~': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('(\\s|^)'+attrValue+'(\\s|$)'))) }; break;
 						case '|': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('^'+attrValue+'-?'))) }; break;
 						case '^': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) == 0) }; break;
 						case '$': checkFunction = function(e) { return (e.getAttribute(attrName).lastIndexOf(attrValue) == e.getAttribute(attrName).length - attrValue.length) }; break;
 						case '*': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) > -1) }; break;
 						default : checkFunction = function(e) { return e.getAttribute(attrName) };
 					}
 					currentContext = [];
 					var currentContextIndex = 0;
 					for (var k = 0; k < found.length; k++) {
 						if (checkFunction(found[k])) {
 							currentContext[currentContextIndex++] = found[k];
 						}
 					}
 					continue;
 				}
 				tagName = token;
 				var found = [], foundCount = 0;
 				for (var h = 0; h < currentContext.length; h++) {
 					var elements = currentContext[h].getElementsByTagName(tagName);
 					for (var j = 0; j < elements.length; j++) {
 						found[foundCount++] = elements[j];
 					}
 				}
 				currentContext = found;
 			}
 			resultList = [].concat(resultList,currentContext);
 		}
 		return resultList;
 	},
 	trim: function (str) {
 		return str.replace(/^\s+/, '').replace(/\s+$/, '');
 	},
 	bind: function(f, scope, forceArgs){
 		return function() {return f.apply(scope, typeof forceArgs !== 'undefined' ? [forceArgs] : arguments);};
 	}
 };
